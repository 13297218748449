import React from "react"
//import { Link } from "gatsby"
import Mailto from 'reactv16-mailto'
import Layout from "../components/Layout"
import {
  Jumbotron,
  Button,
  Container,
  CardDeck,
  Card,
  Row,
  Col,
} from "react-bootstrap"

//import '../../node_modules/react-owl-carousel2/src/owl.carousel.css'

import Apache_logo from "../images/Apache_logo.svg"
import Nginx_logo from "../images/Nginx_logo.svg"
import Debian_logo from "../images/Debian_logo.svg"
import LAMP_logo from "../images/LAMP_logo.svg"
import Mariadb_logo from "../images/Mariadb_logo.svg"
import PHP_logo from "../images/PHP_logo.svg"
import Python_logo from "../images/Python_logo.svg"
import TYPO3_logo from "../images/TYPO3_logo.svg"
import Ubuntu_logo from "../images/Ubuntu_logo.svg"
import WordPress_logo from "../images/WordPress_logo.svg"
import Laravel_logo from "../images/Laravel_logo.svg"
import Xen_logo from "../images/Xen_logo.svg"
import Zimbra_logo from "../images/Zimbra_logo.png"
import Zend_logo from "../images/Zend_logo.svg"

import "../styles/Globals.scss"
import { Helmet } from "react-helmet"

export default function Home() {

  const clickHandler = (e) => {
    //console.log(e);
    window.location.href = e.currentTarget.getAttribute('data-href');
  };

  return (
    <Layout>
      <Helmet>
        <meta name="description" content="Individuelles Hosting für Xen Server, Zimbra Mailserver und LAMP Stacks"></meta>
      </Helmet>
      <Jumbotron fluid>
        <Container>
          <h1>WH Serverpark - Individuelles Hosting</h1>
          <p>
            Wir sind ein Team aus Systemadministratoren und Webentwicklern die
            seit über einem Jahrzehnt Webhosting betreiben und nun unsere
            Resourcen bündeln um noch bessere Umgebungen für Webapplikationen
            anzubieten. Der Grund dafür ist recht einfach, es gab einfach nichts
            was unseren Ansprüchen genügt hat.
          </p>
          <p>
            Alle unsere Angebote leiten sich aus unserer langjährigen Erfahrung
            ab und sind deswegen nicht an den falschen Stellen mit Features
            belastet. Schauen Sie sich unsere Angebote an und wenn Sie etwas
            spezielles wünschen werden wir gemeinsam mit Ihnen eine perfekt
            Lösung finden.
          </p>
          <p>
            <Mailto className={'btn btn-primary'} email='info@wh-serverpark.com' obfuscate={true} headers={{ subject: 'Individuelles Hosting' }}>Kontakt aufnehmen</Mailto>
          </p>
        </Container>
      </Jumbotron>
      <Container id="Angebote">
        <Row>
          <Col>
            <h2 className={'mt-5'}>Individuelles Hosting:</h2>
          </Col>
        </Row>
        <CardDeck>
          <Card data-href="/virtuelle_server" onClick={clickHandler}>
            <Card.Img variant="top" src={Xen_logo} />
            <Card.Body>
              <Card.Title>Virtuelle Xen Server</Card.Title>
              <Card.Text>
                Ihr eigener virtueller Server mit maximaler Verfügbarkeit.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button variant="primary" className={'w-100'} href="/virtuelle_server">
                Weiterlesen
              </Button>
            </Card.Footer>
          </Card>
          <Card data-href="/zimbra" onClick={clickHandler}>
            <Card.Img variant="top" src={Zimbra_logo} />
            <Card.Body>
              <Card.Title>Mail und Groupware</Card.Title>
              <Card.Text>
                Suchen Sie eine komfortable, schnelle und sichere
                Groupware-Lösung?
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button variant="primary" className="w-100" href="/zimbra">
                Weiterlesen
              </Button>
            </Card.Footer>
          </Card>
          <Card data-href="/web_hosting" onClick={clickHandler}>
            <Card.Img variant="top" src={LAMP_logo} />
            <Card.Body>
              <Card.Title>LAMP Stack</Card.Title>
              <Card.Text>
                Ihre individuellen LAMP Appliaktionen genau so wie Sie es
                wünschen.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button variant="primary" className="w-100" href="/web_hosting">
                Weiterlesen
              </Button>
            </Card.Footer>
          </Card>
        </CardDeck>
      </Container>

      <Container>
        <Row>
          <Col>
            <h2 className="mt-5 pt-5">Opensource ist unsere erste Wahl:</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex flex-nowrap  my-4">
              <div class="flex-shringk-1"><img src={Xen_logo} alt="" class="img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={Debian_logo} alt="" class="img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={Ubuntu_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={Apache_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={Nginx_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={Mariadb_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={PHP_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={Python_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={TYPO3_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={WordPress_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={Laravel_logo} alt="" class=" img-fluid"></img></div>
              <div class="flex-shringk-1"><img src={Zend_logo} alt="" class=" img-fluid"></img></div>

            </div>
            <p>Und viele andere Anwendungen sind bei uns zu Hause.</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
